<div class="echarts-container">
    <div class="chart-input-container">
        <div class="RequestIDClass">
            <mat-form-field class="requestInput">
                <mat-label> Enter Request ID</mat-label>
                <input matInput type="text" class="requestInput" [value]="requestId" placeholder="Enter requestId"
                    aria-required="true" (keyup)="changeInRequestId($event)" />
            </mat-form-field>
            <mat-divider ></mat-divider>
        </div>
        
        <div class="visualization-types">
            <mat-form-field *ngIf="yAxisData!=null && yAxisData.length > 0" class="requestInput">
                <mat-label>Enter Visualization Type</mat-label>
                <mat-select aria-required="true" [(value)]="visualizationType"
                    (selectionChange)="changeInVisualizationType($event.value)">
                    <mat-option value="default_visualization">Default Visualizations</mat-option>
                    <mat-option value="data_centric_visualization">Data Centric Visualizations</mat-option>
                </mat-select>
            </mat-form-field>
        
        <div *ngIf="visualizationType=='default_visualization'">
            <mat-form-field *ngIf="yAxisData!=null && yAxisData.length > 0" class="requestInput">
                <mat-label>Enter Chart Type</mat-label>
                <mat-select aria-required="true" [(value)]="chartType"
                    (selectionChange)="changeInChartType($event.value)">
                    <mat-option value="boxplot">Cross-Validation Model Comparison (CVMC) Box Plot</mat-option>
                    <mat-option value="cv_line_chart">Cross-Validation Model Comparison (CVMC) Line Chart</mat-option>
                    <mat-option value="train_test_error">Quantity Curve</mat-option>
                    <mat-option value="test_best_models">Single Model Comparison (SMC) Plot</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="yAxisData!=null && yAxisData.length > 0" class="requestInput">
                <mat-label>Select Metric</mat-label>
                <mat-select aria-required="true" [(value)]="currentMetric"
                    (selectionChange)="changeInCurrentMetric($event.value)">
                    <mat-option *ngFor="let v of yAxisData" [value]="v">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="regressorList!=null && regressorList.length > 0 && chartType == 'train_test_error'"
                class="form-field-styles">
                <mat-label>Select Regressor</mat-label>
                <mat-select aria-required="true" [(value)]="currentRegressor"
                    (selectionChange)="changeInRegressor($event.value)">
                    <mat-option *ngFor="let v of regressorList" [value]="v">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="visualizationType=='data_centric_visualization'">
            <mat-form-field *ngIf="yAxisData!=null && yAxisData.length > 0" class="requestInput">
                <mat-label>Select Visualization Algorithm</mat-label>
                <mat-select aria-required="true" [(value)]="visualizationAlgorithm"
                    (selectionChange)="changeInVisualizationAlgorithm($event.value)">
                    <mat-option value="pca">PCA</mat-option>
                    <mat-option value="tsne">TSNE</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="yAxisData!=null && yAxisData.length > 0" class="requestInput">
                <mat-label>Select Chart Type</mat-label>
                <mat-select aria-required="true" [(value)]="actualChartType"
                    (selectionChange)="changeInChartType($event.value)">
                    <mat-option value="2d">Point Predictability (2D)</mat-option>
                    <mat-option value="3d">Point Predictability (3D)</mat-option>
                    <mat-option value="heatmap">Region Predictability</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="yAxisData!=null && yAxisData.length > 0" class="requestInput">
                <mat-label>Select Metric</mat-label>
                <mat-select aria-required="true" [(value)]="currentMetric"
                    (selectionChange)="changeInCurrentMetric($event.value)">
                    <mat-option *ngFor="let v of yAxisData" [value]="v">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="regressorList!=null && regressorList.length > 0" class="requestInput">
                <mat-label>Select Regressor</mat-label>
                <mat-select aria-required="true" [(value)]="currentRegressor"
                    (selectionChange)="changeInRegressor($event.value)">
                    <mat-option *ngFor="let v of regressorList" [value]="v">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" class="plot-button" (click)="fetchRawData()">Plot</button>
    </div>
</div>

    <div id="chart-div" [hidden]="is3DVisualization() && chartOptions != null" echarts [options]="chartOptions" class="chart-container mat-elevation-z4"></div>
    <!-- <div id="chart-div" [hidden]="is3DVisualization() && chartOptions != null" class="chart-container mat-elevation-z4"></div> -->
    <div [hidden]="!is3DVisualization()" id="plotly-div" class="chart-container mat-elevation-z4"></div>
</div>