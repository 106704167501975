<div class="homepage-container">
    <div class="accordian-container mat-elevation-z4">
        <header class="homepage-header"> 
            <img src="../../../assets/images/ares_logo.png" style="border-radius:50%; margin-left: 10px; margin-top: 12px" alt="logo" height="50px" width="50px"/> 
            <h1>AutoML</h1>
        </header>
        <mat-divider class="divider"></mat-divider>

        <div class="description-container">
            <span class="automl-description">
            AutoML is a Data Centric AI (DCAI) system that uses AI to help scientists build the best regressive model. The system only requires a modicum of information–for example, names of chosen
    regressors, and our system automatically applies dozens of models with different parameters choosing the best models.
            </span>
        </div>
        <a mat-raised-button class="start-here-button" routerLink="/newRequest" ><mat-icon style="color:blueviolet;">play_circle_filled</mat-icon> Get Started Here</a> 
    </div>
    <div class="users-container mat-elevation-z4">
        <app-user-profile-cards></app-user-profile-cards>
    </div>

</div>
