
<div class="main-container">
    <script src="src/assets/Scripts/ecSimpleTransform.min.js"></script>
    <mat-toolbar class="header-toolbar">  
        
    </mat-toolbar>
    <mat-sidenav-container class="side-nav-container">
    <mat-sidenav class="mat-elevation-z8" mode="side" opened>
        <mat-nav-list class="main-navigation-list">
            <header style="font-size:x-large; padding: 10px;"><img src="../../../assets/images/ares_logo.png" style="border-radius:50%"
                alt="logo" height="150px" width="150px"/> </header>
            <mat-divider style="background-color:white;box-shadow:2px white"></mat-divider>
            <div class="nav-list" *ngFor="let li of listItems">
            <a mat-button class="menu-button mat-elevation-z10" routerLink={{li.itemLink}} >
                <mat-icon class="icon-avatar" style="color:antiquewhite; height:24px; width:24px;">{{li.iconName}}</mat-icon>
                <span id="{{li.itemName}}">{{li.itemName}}</span>
                
            </a>
            
        </div>
          </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content >
        
        <router-outlet></router-outlet>
    </mat-sidenav-content>
    </mat-sidenav-container>
</div>